<script>
import SuppliersForm from './SuppliersForm'
import SuppliersApi from '@/api/suppliers'

const DEFAULT_SUPPLIER_ROLE_RETURN_STATUSES = [1, 3, 2, 4]

export default {
  extends: SuppliersForm,

  mixins: [SuppliersApi],

  data() {
    return {
      isNew: true
    }
  },

  computed: {
    warningButtonText() {
      return ''
    }
  },

  methods: {
    handleFormSubmit() {
      if (this.$v.$invalid) {
        this.$refs.form.validate()
        window.snackbar('Ошибки в форме', 'error')
        return
      }

      this.isSubmit = true

      this.createSupplier(this.form)
        .then(() => {
          window.snackbar('Поставщик успешно добавлен', 'info')
          this.$router.push({ name: 'suppliers' })
        })
        .finally(() => { this.afterSubmit() })
    },

    setReturnStatuses(items) {
      this.returnStatusesList = items
      this.form.return_status_ids = DEFAULT_SUPPLIER_ROLE_RETURN_STATUSES
    }
  }
}
</script>